import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import AuthController from '@banno/platform-ux-shared/controllers/auth-controller.js';
import {routingMixin as RouterMixin} from '@jack-henry/web-component-router';
import RoutePaths from '../../js/routing/paths.js';
import ApiPaths from '@banno/platform-ux-shared/services/api-paths.js';

/**
 * @constructor
 * @polymer
 * @extends {PolymerElement}
 */
const LoginLogoutContainerBase = RouterMixin(PolymerElement);
/** @polymer */
class LoginLogoutElement extends LoginLogoutContainerBase {
  static get is() {
    return 'login-logout';
  }

  async routeEnter(currentNode, nextNodeIfExists, routeId, context, next) {
    await super.routeEnter(currentNode, nextNodeIfExists, routeId, context, next);

    function logoutCompleted() {
      try {
        sessionStorage.clear();
      } catch (e) {}
      // do a hard redirect here to clear all in memory references
      const params = [];
      if (context.query.size > 0) {
        if (context.query.get('redirect_to')) {
          params.push(`redirect_to=${encodeURIComponent(context.query.get('redirect_to'))}`);
        }
        if (context.query.get('rolling')) {
          params.push('rolling=true');
        }
        if (context.query.get('absolute')) {
          params.push('absolute=true');
        }
      }
      location.href = `${RoutePaths.LOGIN}${params.length ? '?' : ''}${params.join('&')}`;
    }
    console.log(`Message bus is connected: ${jackHenry.xperience.messageBus.isConnected}`);
    if (jackHenry && jackHenry.xperience && jackHenry.xperience.messageBus &&
        jackHenry.xperience.messageBus.isConnected) {
      jackHenry.xperience.messageBus.disconnect(false);
    }
    AuthController.logout().then(logoutCompleted, logoutCompleted);
  }

  static get template() {
    return html`
    `;
  }
}
customElements.define(LoginLogoutElement.is, LoginLogoutElement);
export default LoginLogoutElement;
